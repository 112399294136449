import Dungeon from '../modules/dungeon'
import RankingData from '../modules/rankingData'

const INITIAL_STATE = {
  status: -1,
  dungeon: Dungeon.FAY_FINAL_PUZZLE,
  ranking: []
}

const ranking = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SHOW':
    case 'RANKING_FETCH_SUCCEEDED':
    case 'RANKING_FETCH_FAILED':
      return {
        status: -1,
        dungeon: Dungeon[action.key],
        ranking: RankingData[action.key].rankingData
      }

    case 'REGISTER_SUCCEEDED':
      return {
        status: action.status,
        dungeon: Dungeon[action.key],
        ranking: RankingData[action.key].rankingData
      }

    case 'SHOW_DETAIL':
      return {
        status: state.status,
        dungeon: state.dungeon,
        ranking: state.ranking,
        detail: action.detail
      }

    default:
      return state
  }
}

export default ranking