import { connect } from 'react-redux'
import Detail from '../components/Detail'
import { showData } from '../actions'

const mapStateToProps = (state) => state

const mapDispatchToProps = (dispatch) => {
  return {
    showData: data => dispatch(showData(data))
  }
}

const DetailData = connect(
  mapStateToProps,
  mapDispatchToProps
)(Detail)

export default DetailData