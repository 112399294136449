import React from 'react'
import '../style/twitter.css'

class Contacts extends React.Component {
  componentDidMount() {
    (window.widgets = window.widgets || []).push({});
  }

  render() {
    return (
      <div className='contact-container'>
        <h2>要望や質問、登録内容が間違っている等</h2>
        <p>Twitterまでお願いします。</p>
        <div className='twitter-container'>
          <a className='twitter-timeline' href='https://twitter.com/PizzaMitsuru?ref_src=twsrc%5Etfw' width='70%' height='600px'>
            Tweets by PizzaMitsuru
          </a>
        </div>
      </div>
    )
  }
}

export default Contacts