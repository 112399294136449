import ky from 'ky'

const url = 'https://api.kouken-party.info/shiren_sp/'

export const fetch_ranking = async (dungeon) => {
  const searchParams = new URLSearchParams()
  searchParams.set('dungeon_id', dungeon.dungeonId)
  let data = JSON.parse(await ky.get(
    'ranking_data',
    {
      prefixUrl: url,
      searchParams: searchParams
    }
  ).json());

  for (let i = 0; i < data.length; i++) {
    data[i].created_date = parseDate(data[i].created_date)
  }
  return data
}

export const register = async (imageData) => {
  const formData = new FormData();
  formData.append('file', imageData);

  let response = JSON.parse(await ky.post(
    'register',
    {
      prefixUrl: url,
      body: formData
    }
  ).json())

  response.data.created_date = parseDate(response.data.created_date)

  return response
}

const parseDate = (dateString) => {
  let date = new Date(dateString)

  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()

  let hours = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()

  return year + "/" + month + "/" + day + " " + hours + ":" + minutes + ":" + seconds
}