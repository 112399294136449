const RankingData = {
  TABLE_MOUNTAIN: {
    updatedAt: null,
    rankingData: []
  },
  FITCHEN_GOD_SHRINE: {
    updatedAt: null,
    rankingData: []
  },
  SCROLL_CAVE: {
    updatedAt: null,
    rankingData: []
  },
  TAINED_PATH: {
    updatedAt: null,
    rankingData: []
  },
  FAY_FINAL_PUZZLE: {
    updatedAt: null,
    rankingData: []
  },
  RAVINE_OF_THE_DEAD: {
    updatedAt: null,
    rankingData: []
  },
  CEREMONIAL_CAVE: {
    updatedAt: null,
    rankingData: []
  },
  ETC: {
    updatedAt: null,
    rankingData: []
  },
}

export default RankingData