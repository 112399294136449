import { connect } from 'react-redux'
import OwnTabs from '../components/OwnTabs'
import { selectTab } from '../actions'

const mapStateToProps = (state) => state

const mapDispatchToProps = (dispatch) => {
  return {
    selectTab: tabIndex => dispatch(selectTab(tabIndex))
  }
}

const Tabs = connect(
  mapStateToProps,
  mapDispatchToProps
)(OwnTabs)

export default Tabs