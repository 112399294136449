import React from 'react'
import PropTypes from 'prop-types'
import '../style/upload-form.css'

const SUCCESS_MESSAGE = '登録が完了しました。'
const INVALID_MESSAGE = '番付のデータは登録できません。'
const FAILED_MESSAGE = '既に登録されているデータです。'

class UploadForm extends React.Component {

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.fileInput = React.createRef()
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.register(this.fileInput.current.files[0])
  }

  render() {
    return (
      <div className='upload-container'>
        <h2>{this.props.title}</h2>
        <p>ダンジョンクリア時のスクリーンショットをアップロードしてください。</p>
        <p>番付画面のスクリーンショットには対応しておりません。</p>
        <form className='upload-form' onSubmit={this.handleSubmit}>
          <input type='file' name='file' ref={this.fileInput} />
          <button className='register-button' type='submit'>登録</button>
        </form>
        <div className='upload-result' hidden={this.props.status === -1 ? true : false}>
          <p>{this.props.status === 0 ? SUCCESS_MESSAGE : this.props.status === 1 ? INVALID_MESSAGE : FAILED_MESSAGE}</p>
        </div>
      </div>
    )
  }
}

UploadForm.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.number.isRequired
  }),
  register: PropTypes.func.isRequired
}

export default UploadForm