const Dungeon = {
  TABLE_MOUNTAIN: { name: "テーブルマウンテン", dungeonId: 1, tabIndex: 0 },
  FITCHEN_GOD_SHRINE: { name: "食神のほこら", dungeonId: 2, tabIndex: 1 },
  SCROLL_CAVE: { name: "掛軸裏の洞窟", dungeonId: 3, tabIndex: 2 },
  TAINED_PATH: { name: "魔蝕虫の道", dungeonId: 4, tabIndex: 3 },
  FAY_FINAL_PUZZLE: { name: "フェイの最終問題", dungeonId: 5, tabIndex: 4 },
  RAVINE_OF_THE_DEAD: { name: "死者の谷底", dungeonId: 6, tabIndex: 5 },
  CEREMONIAL_CAVE: { name: "儀式の洞窟", dungeonId: 7, tabIndex: 6 },
  ETC: { name: "その他", dungeonId: 0, tabIndex: 7 }
}

export default Dungeon