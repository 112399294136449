import { connect } from 'react-redux'
import UploadForm from '../components/UploadForm'
import { register } from '../actions'

const mapStateToProps = (state) => state.data

const mapDispatchToProps = (dispatch) => {
  return {
    register: imageData => dispatch(register(imageData))
  }
}

const UploadContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadForm)

export default UploadContainer