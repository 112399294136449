import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import '../style/detail.css'

const modalRoot = document.getElementById('modal-root');

class Detail extends React.Component {

  constructor(props) {
    super(props);

    this.el = document.createElement('div');
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  close(e) {
    this.props.showData({})
  }

  render() {
    return ReactDOM.createPortal(
      <>
        {(this.props.data.hasOwnProperty('detail') && this.props.data.detail.hasOwnProperty('score')) && (
          <div id='overlay' onClick={e => this.close(e)}>
            <div className='detail-container' >
              <div className='detail-image'>
                <img className='screenshot' alt='スクリーンショット' src={this.props.data.detail ? this.props.data.detail.image_path : null} />
              </div>

              <div className='detail-name'>
                <p>名前: {this.props.data.detail ? this.props.data.detail.name : null}</p>
              </div>
              <div className='detail-score'>
                <p>スコア: {this.props.data.detail ? this.props.data.detail.score : null}</p>
              </div>
              <div className='detail-result'>
                <p>結果: {this.props.data.detail ? this.props.data.detail.result : null}</p>
              </div>
              <div className='detail-floor'>
                <p>到達階数: {this.props.data.detail ? this.props.data.detail.floor : null}</p>
              </div>

              <div className='detail-level'>
                <p>レベル: {this.props.data.detail ? this.props.data.detail.level : null}</p>
              </div>
              <div className='detail-experience'>
                <p>経験値: {this.props.data.detail ? this.props.data.detail.experience : null}</p>
              </div>
              <div className='detail-money'>
                <p>ギタン: {this.props.data.detail ? this.props.data.detail.money : null}</p>
              </div>
              <div className='detail-hp'>
                <p>HP: {this.props.data.detail ? this.props.data.detail.hp : null}</p>
              </div>
              <div className='detail-power'>
                <p>ちから: {this.props.data.detail ? this.props.data.detail.power : null}</p>
              </div>

              <div className='detail-weapon'>
                <p>武器: {this.props.data.detail ? this.props.data.detail.weapon : null}</p>
              </div>
              <div className='detail-shield'>
                <p>盾: {this.props.data.detail ? this.props.data.detail.shield : null}</p>
              </div>
              <div className='detail-bracelet'>
                <p>腕輪: {this.props.data.detail ? this.props.data.detail.bracelet : null}</p>
              </div>
              <div className='detail-created_date'>
                <p>登録日時: {this.props.data.detail ? this.props.data.detail.created_date : null}</p>
              </div>
            </div>
          </div>
        )}
      </>,
      this.el
    )
  }
}

Detail.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.number,
    dungeon: PropTypes.shape({
      name: PropTypes.string.isRequired,
      dungeonId: PropTypes.number.isRequired,
      tabIndex: PropTypes.number.isRequired
    }),
    ranking: PropTypes.array,
    detail: PropTypes.shape({
      name: PropTypes.string,
      dungeon: PropTypes.number,
      result: PropTypes.string,
      floor: PropTypes.string,
      level: PropTypes.number,
      experience: PropTypes.number,
      hp: PropTypes.number,
      power: PropTypes.string,
      money: PropTypes.number,
      score: PropTypes.number,
      weapon: PropTypes.string,
      shield: PropTypes.string,
      bracelet: PropTypes.string,
      image_path: PropTypes.string,
      created_date: PropTypes.string
    })
  }),
  showData: PropTypes.func
}

export default Detail