import { connect } from 'react-redux'
import RankingRow from '../components/RankingRow'
import { showData } from '../actions'

const mapStateToProps = (state) => state.data

const mapDispatchToProps = (dispatch) => {
  return {
    showData: data => dispatch(showData(data))
  }
}

const Row = connect(
  mapStateToProps,
  mapDispatchToProps
)(RankingRow)

export default Row