import React from 'react';
import PropTypes from 'prop-types'
import '../style/ranking-row.css'

class RankingRow extends React.Component {

  onClickRow(e, props) {
    e.stopPropagation()
    props.showData(props.data)
  }

  render() {
    return (
      <ul className='ranking-row' onClick={e => this.onClickRow(e, this.props)} >
        <li className='ranking-rank'>
          {this.props.rank === 0 ? '#' : this.props.rank}
        </li>
        <li className='ranking-score'>
          {this.props.data.score}
        </li>
        <li className='ranking-name'>
          {this.props.data.name}
        </li>
        <li className='ranking-floor'>
          {this.props.data.floor}
        </li>
        <li className='ranking-created_date'>
          {this.props.data.created_date}
        </li>
      </ul>
    )
  }
}

RankingRow.propTypes = {
  rank: PropTypes.number.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    dungeon: PropTypes.number,
    result: PropTypes.string,
    floor: PropTypes.string,
    level: PropTypes.number,
    experience: PropTypes.number,
    hp: PropTypes.number,
    power: PropTypes.string,
    money: PropTypes.number,
    score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    weapon: PropTypes.string,
    shield: PropTypes.string,
    bracelet: PropTypes.string,
    image_path: PropTypes.string,
    created_date: PropTypes.string
  })
}

export default RankingRow