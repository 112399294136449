import { call, put, takeEvery, all } from 'redux-saga/effects'
import { register, fetch_ranking } from './api'
import Dungeon from './modules/dungeon'
import RankingData from './modules/rankingData'

const DAY_MILLISECONDS = 86400000

function* fetchRanking(action) {
  let dungeon
  let key

  for (key in Dungeon) {
    if (Dungeon[key].tabIndex === action.tabIndex) {
      dungeon = Dungeon[key]
      break;
    }
  }

  const now = new Date()
  if (RankingData[key].updatedAt === null || (now - RankingData[key].updatedAt) / DAY_MILLISECONDS >= 1.0) {
    try {
      const ranking = yield call(fetch_ranking, dungeon);
      RankingData[key].updatedAt = now
      RankingData[key].rankingData = ranking

      yield put({ type: "RANKING_FETCH_SUCCEEDED", key: key });
    } catch (e) {
      yield put({ type: "RANKING_FETCH_FAILED", key: key, message: e.message });
    }
  }
  else {
    yield put({ type: 'SHOW', key: key })
  }
}

function* registerData(action) {
  try {
    const response = yield call(register, action.image);
    let key;
    for (key in Dungeon) {
      if (Dungeon[key].dungeonId === response.data.dungeon) break;
    }

    RankingData[key].rankingData.push(response.data)
    RankingData[key].rankingData.sort((a, b) => b.score - a.score)

    yield put({ type: "REGISTER_SUCCEEDED", key: key, status: response.status });
  } catch (e) {
    yield put({ type: "REGISTER_FAILED", message: e.message });
  }
}

function* rankingSaga() {
  yield takeEvery("RANKING_FETCH_REQUESTED", fetchRanking);
}

function* registerSaga() {
  yield takeEvery("REGISTER_REQUESTED", registerData);
}

export default function* rootSagas() {
  yield all([
    rankingSaga(),
    registerSaga()
  ])
}