import React from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  LineShareButton,
  FacebookIcon,
  TwitterIcon,
  LineIcon,
} from 'react-share';
import '../style/header.css'

const url = 'https://shiren.kouken-party.info'

const Header = (props) => (
  <div>
    <h1>{props.title}</h1>
    <div className='sns-container'>
      <div className='share-sns'>
        <FacebookShareButton url={url} className='share-button' >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </div>
      <div className='share-sns'>
        <TwitterShareButton url={url} title={props.title} className='share-button' >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </div>
      <div className='share-sns'>
        <LineShareButton url={url} className='share-button' >
          <LineIcon size={32} round />
        </LineShareButton>
      </div>
    </div>
    <p>風来のシレンSPの非公式web番付です。iOS版、Android版の両方に対応しています。</p>
    <div className='app-links-container'>
      <div>
        <a href='https://play.google.com/store/apps/details?id=jp.co.spike_chunsoft.shiren&hl=ja&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
          <img alt='Google Play で手に入れよう' src='https://play.google.com/intl/ja/badges/images/generic/ja_badge_web_generic.png' width="240px" height='auto' />
        </a>
      </div>
      <div>
        <a href="https://apps.apple.com/jp/app/%E4%B8%8D%E6%80%9D%E8%AD%B0%E3%81%AE%E3%83%80%E3%83%B3%E3%82%B8%E3%83%A7%E3%83%B3-%E9%A2%A8%E6%9D%A5%E3%81%AE%E3%82%B7%E3%83%AC%E3%83%B3/id1450144037?mt=8" style={{ display: "inline-block", overflow: "hidden", background: "url(https://linkmaker.itunes.apple.com/ja-jp/badge-lrg.svg?releaseDate=2019-03-12&kind=iossoftware&bubble=ios_apps) no-repeat", width: "135px", height: "40px" }}>
        </a>
      </div>
    </div>
  </div>
)

export default Header