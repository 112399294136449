import React from 'react'
import { TabPanel } from 'react-tabs'
import PropTypes from 'prop-types'
import RankingRow from '../containers/RankingRow'
import '../style/ranking-panel.css'

const MAX_ROWS = 1000

const HEADER = {
  name: '名前',
  score: 'スコア',
  floor: '階層',
  created_date: '登録日'
}

const RankingPanel = (props) => {
  let rows = []

  for (let i = 0; i < Math.min(props.data.length, MAX_ROWS); i++) {
    rows.push(
      <RankingRow key={props.dungeon + (i + 1)} rank={i + 1} data={props.data[i]} />
    )
  }

  return (
    <TabPanel id={props.id} tabId={props.tabId} selected={props.selected}>
      <h2>{props.dungeon}</h2>
      <div className='ranking-container'>
        <RankingRow id={props.dungeon + 0} rank={0} data={HEADER} />
        {rows}
      </div>
    </TabPanel>
  )
}

RankingPanel.tabsRole = 'TabPanel'

RankingPanel.propTypes = {
  dungeon: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    dungeon: PropTypes.number,
    result: PropTypes.string,
    floor: PropTypes.string,
    level: PropTypes.number,
    experience: PropTypes.number,
    hp: PropTypes.number,
    power: PropTypes.string,
    money: PropTypes.number,
    score: PropTypes.number,
    weapon: PropTypes.string,
    shield: PropTypes.string,
    bracelet: PropTypes.string,
    image_path: PropTypes.string,
    created_date: PropTypes.string
  })),
  id: PropTypes.string,
  tabId: PropTypes.string,
  selected: PropTypes.bool
}

export default RankingPanel