import React from 'react'
import { Tab, Tabs, TabList } from 'react-tabs'
import PropTypes from 'prop-types'
import "react-tabs/style/react-tabs.css"
import RankingPanel from './RankingPanel'
import Dungeon from '../modules/dungeon'

class OwnTabs extends React.Component {

  constructor(props) {
    super(props)

    this.tabs = []

    for (let key in Dungeon) {
      this.tabs.push(
        <Tab key={Dungeon[key].dungeonId.toString()}>
          {Dungeon[key].name}
        </Tab>
      )
    }
  }

  componentDidMount() {
    this.props.selectTab(this.props.data.dungeon.tabIndex)
  }

  render() {
    let panels = []

    for (let key in Dungeon) {
      if (Dungeon[key].dungeonId === this.props.data.dungeon.dungeonId) {
        panels.push(
          <RankingPanel key={this.props.data.dungeon.dungeonId.toString()} dungeon={this.props.data.dungeon.name} data={this.props.data.ranking} />
        )
      } else {
        panels.push(
          <RankingPanel key={Dungeon[key].dungeonId.toString()} dungeon={Dungeon[key].name} data={[]} />
        )
      }
    }

    return (
      <Tabs
        selectedIndex={this.props.data.dungeon.tabIndex}
        onSelect={index => this.props.selectTab(index)}>

        <TabList>
          {this.tabs}
        </TabList>

        {panels}
      </Tabs>
    )
  }
}

OwnTabs.propTypes = {
  data: PropTypes.shape({
    dungeon: PropTypes.shape({
      name: PropTypes.string.isRequired,
      dungeonId: PropTypes.number.isRequired,
      tabIndex: PropTypes.number.isRequired
    }),
    ranking: PropTypes.array
  }),
  selectTab: PropTypes.func.isRequired
}

export default OwnTabs