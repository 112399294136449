export const selectTab = (tabIndex) => {
    return {
        type: 'RANKING_FETCH_REQUESTED',
        tabIndex
    }
}

export const register = (data) => {
    return {
        type: 'REGISTER_REQUESTED',
        image: data
    }
}

export const showData = (detail) => {
    return {
        type: 'SHOW_DETAIL',
        detail
    }
}