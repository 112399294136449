import React from 'react'
import PropTypes from 'prop-types'
import './App.css'
import "react-tabs/style/react-tabs.css"
import Tabs from '../containers/Tabs'
import Detail from '../containers/Detail'
import Header from './Header'
import UploadForm from '../containers/UploadForm'
import Contacts from './Contacts'
import Footer from './Footer'

const App = (props) => (
  <div className="shiren-sp-ranking" >
    <Detail />
    <Header title={props.title} />
    <UploadForm title='登録フォーム' />
    <Tabs />
    <Contacts />
    <Footer />
  </div >
)

App.propTypes = {
  title: PropTypes.string.isRequired
}

export default App